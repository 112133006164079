export const ENDPOINTS = {
  LOGIN: "oauth/token",
  SAIR: "oauth/sair",
  MEUS_DADOS: "user/meus-dados",
  GET_USER: "funcionario/buscar",
  LIST_USER: "funcionario/listar",
  LIST_PERMISSION: "permissao/listar",
  LIST_COLABORADOR: "funcionario/listar-colaboradores",
  LIST_MESAS: "mesa/listar",
  EXIBIR_FUNCIONARIO: "funcionario/exibir",
  EDITAR_ACESSOS_DO_FUNCIONARIO: "funcionario/editar-acessos",
  IMPORTAR_FUNCIONARIO: "funcionario/importar",
  LISTAR_MEDICO_APROVADORES: "funcionario/listar-aprovadores",
  LISTAR_REPRESENTANTES: "funcionario/listar-representantes",
  BUSCAR_EMPRESA_PARCEIRA: "empresa_parceira/buscar",
  CRIAR_EMPRESA_PARCEIRA: "empresa_parceira/criar",
  EXIBIR_EMPRESA_PARCEIRA: "empresa_parceira/exibir",
  EDITAR_EMPRESA_PARCEIRA: "empresa_parceira/editar",
  EXCLUIR_EMPRESA_PARCEIRA: "empresa_parceira/excluir",
  LISTAR_EMPRESA_PARCEIRA: "empresa_parceira/listar",
  BUSCAR_PRODUTO: "produto/buscar",
  EXCLUIR_PRODUTO: "produto/excluir",
  CRIAR_PRODUTO: "produto/criar",
  EXIBIR_PRODUTO: "produto/exibir",
  EDITAR_PRODUTO: "produto/editar",
  LISTAR_PRODUTO: "produto/listar",
  LISTAR_LINHA: "linha/listar",
  BUSCAR_LINHA: "linha/buscar",
  CRIAR_LINHA: "linha/criar",
  EDITAR_LINHA: "linha/editar",
  EXIBIR_LINHA: "linha/exibir",
  EXCLUIR_LINHA: "linha/excluir",
  BUSCAR_ESPECIALIDADE: "especialidade_medica/buscar",
  CRIAR_ESPECIALIDADE: "especialidade_medica/criar",
  EDITAR_ESPECIALIDADE: "especialidade_medica/editar",
  EXIBIR_ESPECIALIDADE: "especialidade_medica/exibir",
  EXCLUIR_ESPECIALIDADE: "especialidade_medica/excluir",
  LISTAR_ESPECIALIDADE: "especialidade_medica/listar",
  BUSCAR_TAG: "tag/buscar",
  CRIAR_TAG: "tag/criar",
  EDITAR_TAG: "tag/editar",
  EXIBIR_TAG: "tag/exibir",
  EXCLUIR_TAG: "tag/excluir",
  LISTAR_TAG: "tag/listar",
  BUSCAR_CATEGORIA: "categoria/buscar",
  CRIAR_CATEGORIA: "categoria/criar",
  EDITAR_CATEGORIA: "categoria/editar",
  EXIBIR_CATEGORIA: "categoria/exibir",
  EXCLUIR_CATEGORIA: "categoria/excluir",
  LISTAR_CATEGORIA: "categoria/listar",
  TODAS_CATEGORIAS: "categoria/todos-hierarquia",
  LISTAR_MEDICO_SOLICITANTE: "medico_solicitante/listar",
  BUSCAR_MEDICO_SOLICITANTE: "medico_solicitante/buscar",
  EDITAR_MEDICO_SOLICITANTE: "medico_solicitante/editar",
  EXIBIR_MEDICO_SOLICITANTE: "medico_solicitante/exibir",
  EXCLUIR_MEDICO_SOLICITANTE: "medico_solicitante/excluir",
  CRIAR_MEDICO_SOLICITANTE: "medico_solicitante/criar",
  EXIBIR_DADOS_CADASTRAIS_MEDICO_SOLICITANTE: "medico_solicitante/exibir-dados-cadastrais",
  EXCLUIR_DADOS_CADASTRAIS_MEDICO_SOLICITANTE: "medico_solicitante/solicitar-exclusao",
  LISTAR_ESPECIALIDADES_MEDICO_SOLICITANTE: "medico_solicitante/listar-especialidades",
  EDITAR_DADOS_CADASTRAIS_MEDICO_SOLICITANTE: "medico_solicitante/editar-dados-cadastrais",
  VISUALIZAR_SOLICITACAO_MEDICO_SOLICITANTE: "medico_solicitante/visualizar-solicitacao",
  EDITAR_CONFIG: "config/editar",
  EXIBIR_CONFIG: "config/exibir",
  BUSCAR_ARTIGO: "artigo/buscar",
  CRIAR_ARTIGO: "artigo/criar",
  EDITAR_ARTIGO: "artigo/editar",
  EXIBIR_ARTIGO: "artigo/exibir",
  EXCLUIR_ARTIGO: "artigo/excluir",
  LISTAR_ARTIGO: "artigo/listar",
  OBTER_LINK_ARTIGO: "artigo/obter-link",
  BUSCAR_MODELO: "modelo/buscar",
  CRIAR_MODELO: "modelo/criar",
  DUPLICAR_MODELO: "modelo/duplicar",
  EDITAR_MODELO: "modelo/editar",
  EXIBIR_MODELO: "modelo/exibir",
  EXCLUIR_MODELO: "modelo/excluir",
  LISTAR_MODELO: "modelo/listar",
  PREVIEW_PDf_MODELO: "modelo/preview-pdf",
  DADOS_SOLICITACAO: "solicitacao/dados",
  BUSCAR_SOLICITACAO: "solicitacao/buscar",
  CRIAR_SOLICITACAO: "solicitacao/criar",
  CRIAR_PARA_TREINAMENTOS_SOLICITACAO: "solicitacao/criar-para-treinamentos",
  EXIBIR_SOLICITACAO: "solicitacao/exibir",
  EDITAR_SOLICITACAO: "solicitacao/editar",
  EDITAR_PARA_TREINAMENTOS_SOLICITACAO: "solicitacao/editar-para-treinamentos",
  INICIAR_ATENDIMENTO_SOLICITACAO: "solicitacao/iniciar-atendimento",
  DEIXAR_ATENDIMENTO_SOLICITACAO: "solicitacao/deixar-atendimento",
  ENCAMINHAR_SOLICITACAO: "solicitacao/encaminhar",
  PEDIR_COMPLEMENTO_SOLICITACAO: "solicitacao/pedir-complemento",
  INSERIR_COMENTARIO_SOLICITACAO: "solicitacao/inserir-comentario",
  MINHAS_SOLICITACOES_SOLICITACAO: "solicitacao/minhas-solicitacoes",
  EXCLUIR_SOLICITACAO: "solicitacao/excluir",
  DADOS_POSTAGEM_SOLICITACAO: "solicitacao/dados-postagem",
  ENCAMINHAR_APROVACAO_SOLICITACAO: "solicitacao/encaminhar-aprovacao",
  RESPONDER_APROVACAO_SOLICITACAO: "solicitacao/responder-aprovacao",
  FINALIZAR_ENVIAR_SOLICITACAO: "solicitacao/finalizar-enviar",
  REENVIAR_RESPOSTA_SOLICITACAO: "solicitacao/reenviar-resposta",
  AVALIAR_ATENDIMENTO_SOLICITACAO: "solicitacao/avaliar-atendimento",
  EXCLUIR_COMENTARIO_SOLICITACAO: "solicitacao/excluir-comentario",
  FINAZLIZAR_SEM_RESPOSTA_SOLICITACAO: "solicitacao/finalizar-sem-resposta",
  CONSULTA_INTERNA_SOLICITACAO: "solicitacao/consulta-interna",
  EXIBIR_CARTA: "carta/exibir",
  CONVERTER_EM_MODELO_CARTA: "carta/converter-em-modelo",
  BUSCAR_CARTA: "carta/buscar",
  CRIAR_CARTA: "carta/criar",
  EDITAR_CARTA: "carta/editar",
  PREVIEW_PDF_CARTA: "carta/preview-pdf",
  RELATORIO_DASHBOARD: "relatorio/dashboard",
  RELATORIO_DASHBOARD_EXPORT: "relatorio/dashboard/export",
  RELATORIO_SOLICITACOES_EXPORT: "relatorio/solicitacao/export",
  RELATORIO_ATENDIMENTOS_MEDICO_APROVADOR: "relatorio/atendimento/aprovador", 
  RELATORIO_ATENDIMENTOS_MEDICO_APROVADOR_EXEL: "relatorio/atendimento/aprovador/export",
  RELATORIO_ATENDIMENTOS_COLABORADOR: "relatorio/atendimento/colaborador",
  RELATORIO_ATENDIMENTOS_COLABORADOR_EXEL: "relatorio/atendimento/colaborador/export",
  RELATORIO_SOLICITACOES_POR_PRODUTO: "relatorio/solicitacoes/produto",
  RELATORIO_SOLICITACOES_POR_PRODUTO_EXEL: "relatorio/solicitacoes/produto/export",
  RELATORIO_SOLICITACOES_POR_LINHA: "relatorio/solicitacoes/linha",
  RELATORIO_SOLICITACOES_POR_LINHA_EXEL: "relatorio/solicitacoes/linha/export",
};

export const ESTADOS_LIST = [
  { value: "AC", text: "Acre" },
  { value: "AL", text: "Alagoas" },
  { value: "AP", text: "Amapá" },
  { value: "AM", text: "Amazonas" },
  { value: "BA", text: "Bahia" },
  { value: "CE", text: "Ceará" },
  { value: "ES", text: "Espírito Santo" },
  { value: "GO", text: "Goiás" },
  { value: "MA", text: "Maranhão" },
  { value: "MT", text: "Mato Grosso" },
  { value: "MS", text: "Mato Grosso do Sul" },
  { value: "MG", text: "Minas Gerais" },
  { value: "PA", text: "Pará" },
  { value: "PB", text: "Paraíba" },
  { value: "PR", text: "Paraná" },
  { value: "PE", text: "Pernambuco" },
  { value: "PI", text: "Piauí" },
  { value: "RJ", text: "Rio de Janeiro" },
  { value: "RN", text: "Rio Grande do Norte" },
  { value: "RS", text: "Rio Grande do Sul" },
  { value: "RO", text: "Rondônia" },
  { value: "RR", text: "Roraima" },
  { value: "SC", text: "Santa Catarina" },
  { value: "SP", text: "São Paulo" },
  { value: "SE", text: "Sergipe" },
  { value: "TO", text: "Tocantins" },
  { value: "DF", text: "Distrito Federal" },
];
