import applyCaseMiddleware from "axios-case-converter";
import axios from "axios";
import Cookies from "js-cookie";

const backendBaseURL = process.env.VUE_APP_API + "api/v1/";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const headersFormData = {
  "Content-Type": "multipart/form-data",
}

const success = (res) => res;

const error = (err) => {
  if (
    // err.response.data.message === "O Token não é valido" &&
    err.response.status === 401
  ) {
    Cookies.remove("loggedIn");
    Cookies.remove("userData");
    window.location = process.env.VUE_APP_LOGIN;
  } else {
    return Promise.reject(err);
  }
};

export const apiInstance = applyCaseMiddleware(
  axios.create({
    headers: headers,
    baseURL: backendBaseURL,
  })
);

export const apiInstanceConfig = axios.create({
  headers: headers,
  baseURL: backendBaseURL,
});

export const apiInstanceFormData = axios.create({
  headers: headersFormData,
  baseURL: backendBaseURL,
});

apiInstance.interceptors.response.use(success, error);

apiInstance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiInstanceConfig.interceptors.response.use(success, error);

apiInstanceConfig.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiInstanceFormData.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
